import { FooterData } from '../../constants/Data';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='footer__Container'>
				{/* Footer Top */}
				<div className='footer__Top'>
					<Link to='/' className='footer__Top--Left'>
						<img src='/HMSLogo.png' alt='hms-logo' />
					</Link>
					<div className='footer__Top--Right'>
						<Link
							to='https://www.linkedin.com/company/horizon-market-strategies/'
							target='_blank'>
							<img
								src='/LinkedInIcon.svg'
								alt='linkedin-icon'
							/>
						</Link>
						<Link
							to='https://www.youtube.com/@HorizonmarketStrategies'
							target='_blank'>
							{' '}
							<img
								src='/youtubeFooterIcon.webp'
								alt='youtube-icon'
							/>
						</Link>
					</div>
				</div>
				{/* Footer Middle */}
				<div className='footer__Middle'>
					{FooterData.map((item) => (
						<div className='footer__Links' key={item.id}>
							<h3>{item.title}</h3>
							{item.links.map((link) => (
								<div className='footer__Link' key={link.id}>
									<Link
										to={link.url}
										className='footer__Item'>
										{link.name}
									</Link>
								</div>
							))}
						</div>
					))}
				</div>
				{/* Contact */}
				<div className='footer__Number'>
					<a href='tel:+447700142117'>+44 7700 142117</a>
					<a href='tel:+12136167815'>+1 213 616 7815</a>
				</div>
				<hr />
				{/* Footer Bottom */}
				<div className="footer__Bottom">
					<p><a href='mailto:info@horizon-markets.com'>Write Us</a></p>
					<p><Link to="/cookie-policy">Cookie Policy</Link></p>
					<p><Link to="/privacy">Privacy</Link></p>
					<p><Link to="/refund-policy">Return & Refund Policy</Link></p>
					<p>© HMS {new Date().getFullYear()}. All rights reserved.</p>
        		</div>
			</div>
		</footer>
	);
};

export default Footer;
