import { lazy, Suspense } from 'react';
import {
	BrowserRouter,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import { ErrorPage } from './pages';
import { Footer, Loader, Navbar } from './components';
import './css/main.css';
import UserAgreement from './pages/byor/byor.js';

const Byor = lazy(() => import('./pages/byor/byor.jsx'));
const Thankyou = lazy(() =>
	import('./pages/byor/ThankYouPage.jsx')
);
const HomeLazy = lazy(() =>
	import('./pages/Home/Home.router')
);
const CareersLazy = lazy(() =>
	import('./pages/Careers/Careers.router')
);
const LoginLazy = lazy(() =>
	import('./pages/Authentication/Login.router.jsx')
);
const RegisterLazy = lazy(() =>
	import('./pages/Authentication/Register.router.jsx')
);
const TeamsLazy = lazy(() =>
	import('./pages/Teams/Teams.router')
);
const AboutLazy = lazy(() =>
	import('./pages/About/About.router')
);
const CapabilitiesLazy = lazy(() =>
	import('./pages/Capabilities/Capabilities.router')
);
const StoreLazy = lazy(() =>
	import('./pages/Order/Store/Store.router')
);
const OrderDetailsLazy = lazy(() =>
	import('./pages/Order/OrderDetails/OrderDetails.router')
);
const CartLazy = lazy(() =>
	import('./pages/Order/Cart/Cart.router')
);
const BlogLazy = lazy(() =>
	import('./pages/Blogs/Blog/Blog.router')
);
const BlogPostLazy = lazy(() =>
	import('./pages/Blogs/BlogPost/BlogPost.router')
);
const ReportsDetailsLazy = lazy(() =>
	import(
		'./pages/Reports/ReportsDetails/ReportsDetails.router'
	)
);
const AdminDashboardLazy = lazy(() =>
	import(
		'./pages/Admin/AdminDashboard/AdminDashboard.router'
	)
);
const ItemSelectionLazy = lazy(() =>
	import('./pages/ItemSelection/ItemSelection.router')
);
const CheckoutLazy = lazy(() =>
	import('./pages/Checkout/Checkout.router')
);
// const RdPublishingLazy = lazy(() => import('./pages/RDPublishing/RdPubslishing.router'));
const StoreInsideLazy = lazy(() =>
	import('./pages/StoreInside/StoreInside.router')
);
const ContactLazy = lazy(() =>
	import('./pages/Contact/Contact.router')
);
const ReportPublish = lazy(() =>
	import(
		'./pages/Reports/ReportPublish/ReportPublish.router.jsx'
	)
);
const CompetitiveLandscapeLazy = lazy(() =>
	import(
		'./pages/CompetitiveLandscape/CompetitiveLandscape.router'
	)
);
const ResearchLazy = lazy(() =>
	import('./pages/Research/Research.router')
);
const ComingSoonLazy = lazy(() =>
	import('./pages/ComingSoon/ComingSoon.router')
);
const Reports = lazy(() =>
	import('./pages/Reports/index.js')
);
const RefundPolicyLazy = lazy(() =>
	import('./pages/RefundPolicy/RefundPolicy.router.jsx')
);
const PrivacyLazy = lazy(() =>
	import('./pages/Privacy/Privacy.router.jsx')
);
const CookiePolicyLazy = lazy(() =>
	import('./pages/CookiePolicy/CookiePolicy.router.jsx')
);

const App = () => {
	const location = useLocation();

	const renderNavbarAndFooter = location.pathname !== '/';
	console.log(
		location.pathname !== '/' ||
			location.pathname !== '/thank-you',
		location.pathname,
		location.pathname !== '/thank-you'
	);
	return (
		<>
			{location.pathname !== '/' &&
				location.pathname !== '/thank-you' && <Navbar />}
			<Routes>
				<Route
					path='/thank-you'
					element={
						<Suspense fallback={<Loader />}>
							<Thankyou />
						</Suspense>
					}
				/>
				<Route
					path='/'
					element={
						<Suspense fallback={<Loader />}>
							<Byor />
						</Suspense>
					}
				/>
				<Route
					path='/byor-agreement'
					element={
						<Suspense fallback={<Loader />}>
							<UserAgreement />
						</Suspense>
					}
				/>
				<Route path='*' element={<ErrorPage />} />
				{/* <Route
					path='/'
					element={
						<Suspense fallback={<Loader />}>
							<HomeLazy />
						</Suspense>
					}
				/>
				<Route
					path='/sign-in'
					element={
						<Suspense fallback={<Loader />}>
							<LoginLazy />
						</Suspense>
					}
				/>
				<Route
					path='/sign-up'
					element={
						<Suspense fallback={<Loader />}>
							<RegisterLazy />
						</Suspense>
					}
				/>
				<Route
					path='/industry-outlook'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/>
				<Route
					path='/market-database'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/>
				<Route
					path='/industry-news'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/>
				<Route
					path='/mega-trends'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/>
				<Route
					path='/whitepapers'
					element={
						<Suspense fallback={<Loader />}>
							<ComingSoonLazy />
						</Suspense>
					}
				/>
				<Route
					path='/teams'
					element={
						<Suspense fallback={<Loader />}>
							<TeamsLazy />
						</Suspense>
					}
				/>
				<Route
					path='/company/careers'
					element={
						<Suspense fallback={<Loader />}>
							<CareersLazy />
						</Suspense>
					}
				/>
				<Route
					path='/capabilities'
					element={
						<Suspense fallback={<Loader />}>
							<CapabilitiesLazy />
						</Suspense>
					}
				/>
				<Route
					path='/company/about'
					element={
						<Suspense fallback={<Loader />}>
							<AboutLazy />
						</Suspense>
					}
				/>
				<Route
					path='/competitive-landscape'
					element={
						<Suspense fallback={<Loader />}>
							<CompetitiveLandscapeLazy />
						</Suspense>
					}
				/>
				<Route
					path='/research'
					element={
						<Suspense fallback={<Loader />}>
							<ResearchLazy />
						</Suspense>
					}
				/>
				<Route
					path='/store'
					element={
						<Suspense fallback={<Loader />}>
							<StoreLazy />
						</Suspense>
					}
				/>
				<Route
					path='/store/:id'
					element={
						<Suspense fallback={<Loader />}>
							<OrderDetailsLazy />
						</Suspense>
					}
				/>
				<Route
					path='/cart'
					element={
						<Suspense fallback={<Loader />}>
							<CartLazy />
						</Suspense>
					}
				/>
				<Route
					path='/blog'
					element={
						<Suspense fallback={<Loader />}>
							<BlogLazy />
						</Suspense>
					}
				/>
				<Route
					path='/blog/:id'
					element={
						<Suspense fallback={<Loader />}>
							<BlogPostLazy />
						</Suspense>
					}
				/>
				<Route
					path='/reports'
					element={
						<Suspense fallback={<Loader />}>
							<Reports />
						</Suspense>
					}
				/>
				<Route
					path='/reports/:category'
					element={
						<Suspense fallback={<Loader />}>
							<Reports />
						</Suspense>
					}
				/>
				<Route
					path='/reports/:category/:subcategory'
					element={
						<Suspense fallback={<Loader />}>
							<Reports />
						</Suspense>
					}
				/>
				<Route
					path='/report/:id'
					element={
						<Suspense fallback={<Loader />}>
							<ReportsDetailsLazy />
						</Suspense>
					}
				/>
				<Route
					path='/publish/report'
					element={
						<Suspense fallback={<Loader />}>
							<ReportPublish />
						</Suspense>
					}
				/>
				<Route
					path='/dashboard'
					element={
						<Suspense fallback={<Loader />}>
							<AdminDashboardLazy />
						</Suspense>
					}
				/>
				<Route
					path='/select-items'
					element={
						<Suspense fallback={<Loader />}>
							<ItemSelectionLazy />
						</Suspense>
					}
				/>
				<Route
					path='/checkout'
					element={
						<Suspense fallback={<Loader />}>
							<CheckoutLazy />
						</Suspense>
					}
				/>
		
				<Route
					path='/store-inside'
					element={
						<Suspense fallback={<Loader />}>
							<StoreInsideLazy />
						</Suspense>
					}
				/>
				<Route
					path='/contact'
					element={
						<Suspense fallback={<Loader />}>
							<ContactLazy />
						</Suspense>
					}
				/>
				<Route
					path='/refund-policy'
					element={
						<Suspense fallback={<Loader />}>
							<RefundPolicyLazy />
						</Suspense>
					}
				/>
				<Route
					path='/privacy'
					element={
						<Suspense fallback={<Loader />}>
							<PrivacyLazy />
						</Suspense>
					}
				/>
				<Route
					path='/cookie-policy'
					element={
						<Suspense fallback={<Loader />}>
							<CookiePolicyLazy />
						</Suspense>
					}
				/>
				*/}
			</Routes>
			{renderNavbarAndFooter && <Footer />}
		</>
	);
};

const AppWrapper = () => {
	return (
		<BrowserRouter>
			<App />
		</BrowserRouter>
	);
};

export default AppWrapper;
