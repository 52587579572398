import { useEffect, useState, memo } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

// Home Cards :
const CardHome = memo(({ data }) => {
	return (
		<article className='cardHome'>
			<img src={data.url} alt={data.url} />
			<h3>{data.heading}</h3>
			<p>{data.details}</p>
		</article>
	);
});

export default CardHome;

// Service Cards :
export const CardServiceHome = memo(({ data }) => {
	return (
		<article
			className='cardServiceHome'
			data-testid='service-card'>
			<div className='cardServiceHome__Wrapper'>
				<img src={data.url} alt={data.id} />
			</div>
			<h1>{data.heading}</h1>
			<p>{data.details}</p>
		</article>
	);
});

// Reports Cards :
export const CardReportsHome = memo(({ data, onClick }) => {


	const formatDate = (dateString) => {
		const dateObject = new Date(dateString);
		const options = {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		};
		return dateObject.toLocaleDateString('en-US', options);
	};

	const formattedDate = formatDate(data.date);
	const [day, month, year] = formattedDate.split(' ');

	return (
		<div
			data-testid='report-cards'
			className='cardReportsHome relative transition-transform duration-500 bg-[#D4D4D41C] ease-in-out hover:bg-[#042E3E] w-full h-[50rem] overflow-hidden group hover:text-[#FFF]'
			onClick={onClick}>
			<div className='cardReportsHome__Data'>
				<div className='cardReportsHome__Top'>
					<h1>{data.header}</h1>
					<p>{data.title}</p>
				</div>
				
			</div>
			<div className='cardReportsHome__Image'>
				<img
					src={data.image || '/HomeWorkflowImg.svg'}
					alt='Workflow'
					className='w-full object-cover'
				/>
			</div>
		</div>
	);
});

// Teams Cards :
export const TeamsCard = memo(({ data }) => {
	return (
		<article
			data-testid='teams-card'
			className='teamsCard relative transition-transform duration-500 bg-[#D4D4D41C] ease-in-out hover:bg-[#042E3E] overflow-hidden group hover:text-[#FFF]'>
			<div className='teamCard__Initial w-full'>
				<img src={data.img} alt={String(data.id)} />
				<div className='teamsCard__Info'>
					<h4>{data.title}</h4>
					<p>{data.subTitle}</p>
				</div>
			</div>
			<div className='teamCard__HoverData'>
				<div className='teamCard__TopIcons'>
					<Link
						to={data.URL}
						target='_blank'
						rel='noopener noreferrer'>
						<img src="/TeamsLinkedInIcon.svg" alt="" />
					</Link>
				</div>
				{/* <div className='teamCard__MidData'>
					In the early morning, vibrant colors filled the
					sky as the sun began its ascent, casting a golden
					hue over the landscape.
				</div> */}
			</div>
		</article>
	);
});

// Store Cards :
export const StoreCard = memo(
	({ img, title, price, id }) => {
		return (
			<article className='storeCard'>
				<img
					src="/StoreCardImg.svg"
					alt={title}
					className='store-card__img'
				/>
				<h3>{title}</h3>
				<div className='storeCard__Bottom'>
					<p>{price}$</p>
					<Link to={`/store/${id}`}>
						<div className='storeCard__Cart'>
							<img
								src='/StoreCartIcon.svg'
								alt='cart-icon'
							/>
						</div>
					</Link>
				</div>
			</article>
		);
	}
);

// Order Details Cards :
export const OrderDetailsCard = memo(
	({ img, title, price, id }) => {
		return (
			<article className='orderDetailsCard'>
				<img
					src={img}
					alt={title}
					className='store-card__img'
				/>
				<h3>{title}</h3>
				<div className='orderDetailsCard__Bottom'>
					<p>{price}$</p>
					<Link to={`/store/${id}`}>
						<div className='orderDetailsCard__Cart'>
							<img
								src={'/StoreCartIcon.svg'}
								alt='cart-icon'
							/>
						</div>
					</Link>
				</div>
			</article>
		);
	}
);

// Cart Cards :
export const CartCard = memo(
	({ id, img, title, price }) => {
		return (
			<article className='cartCard'>
				<div className='cartCard__Left'>
					<img src={img} alt={String(id)} />
				</div>
				<div className='cartCard__Right'>
					<h1>{title}</h1>
					<div className='cartCard__Options'>
						<div className='cartCard__Btns'>
							<button>-</button>
							<span>1</span>
							<button>+</button>
						</div>
						<p>{price}$</p>
					</div>
					<button
						type='button'
						className='cartCard__RemoveBtn'>
						Remove
					</button>
				</div>
			</article>
		);
	}
);

// Blog Cards :
export const BlogCard = memo(
	({ urlSlug, img, title, content }) => {
		return (
			<Link to={`/report/${urlSlug}`}>
				<article className='blogCard'>
					<img src={img} alt={urlSlug} />
					<h3>{title}</h3>
					<p>{content}</p>
				</article>
			</Link>
		);
	}
);
