import React, { useState } from 'react';
import './Login.styles.css';
import { Link, useNavigate } from 'react-router-dom';

import { useForm } from '@tanstack/react-form';
import { yupValidator } from '@tanstack/yup-form-adapter';
import * as yup from 'yup';
import { Button } from '../../components';
import 'react-intl-tel-input/dist/main.css';
import Token from '../../lib/token.js';
// import {
// 	resendTimerCountState,
// 	loginCredentialState,
// } from '../../store/auth';

import CookieToken from '../../lib/cookie-token';
import {
	ACCESS_TOKEN_KEY,
	REFRESH_TOKEN_KEY,
} from '../../constants/token.constant.js';
// import { userState } from '../../store/user.js';
// import { useMutation } from 'react-query';
import {
	postInitiateSignin,
	postSignin,
} from '../../repositories/auth';
// import { useRecoilState } from 'recoil';
import { useCountdownTimer } from '../../hooks/hooks';

function FieldInfo({ field }) {
	return (
		<div className='text-[1.4rem] font-[500] text-red-500'>
			{field.state.meta.touchedErrors ? (
				<p>{field.state.meta.touchedErrors}</p>
			) : null}
		</div>
	);
}
const userState = 'as';
const useRecoilState = () => {};
const useMutation = () => {};
const Login = () => {
	const navigate = useNavigate();
	const [seconds, resetTimer] = useCountdownTimer();
	const [loginCredentials, setLoginCredentials] =
		useRecoilState();
	const [resendTimeCount, setResendTimerCount] =
		useRecoilState();

	const [_user, setUser] = useRecoilState(userState);
	const [otpId, setOtpId] = useState('');
	const [error, setError] = useState('');
	const handleInitiate = (value) => {
		const { email, password } = value;
		setError('');
		signInInitiateMutate.mutate({
			email,
			password,
		});
		setLoginCredentials({
			email,
			password,
		});
		resetTimer();
	};
	const handlefinal = (value) => {
		const { otp } = value;
		setError('');
		signInMutate.mutate({
			otp,
			otpId,
		});
	};

	const initiateForm = useForm({
		defaultValues: {
			email: '',
			password: '',
		},
		onSubmit: async ({ value }) => handleInitiate(value),
		validatorAdapter: yupValidator(),
	});
	const finalForm = useForm({
		defaultValues: {
			otp: '',
		},
		onSubmit: async ({ value }) => handlefinal(value),
		validatorAdapter: yupValidator(),
	});

	const signInInitiateMutate = useMutation(
		postInitiateSignin,
		{
			onSuccess: (response) => {
				setOtpId(response?.data?.otpId);
			},

			onError: (error) => {
				setError(error.message);
			},
		}
	);
	const signInMutate = useMutation(postSignin, {
		onSuccess: (response) => {
			CookieToken.setToken(
				ACCESS_TOKEN_KEY,
				response.data.accessToken
			);
			Token.setToken(
				REFRESH_TOKEN_KEY,
				response.data.refreshToken
			);
			setUser({
				token: response.data.accessToken,
				email: response.data.email,
				image: response.data.image,
				name: response.data.username,
			});
			navigate('/', { replace: true });
		},

		onError: (error) => setError(error.message),
	});

	return (
		<main className='login'>
			<div className='login__Container'>
				<div className='login__Left'>
					<img src={'/HMSLogo.png'} alt='Logo' />
					<div className='login__header'>
						<h1>Log in to your Account</h1>
						<p>Welcome back! Select method to log in:</p>
					</div>
					<div className='login__form'>
						{!otpId ? (
							<form
								onSubmit={(e) => {
									e.preventDefault();
									e.stopPropagation();
									initiateForm.handleSubmit();
								}}>
								<initiateForm.Field
									name='email'
									validators={{
										onChange: yup
											.string()
											.email('Invalid email address')
											.required('Email is required'),
									}}
									children={(field) => (
										<div className='flex flex-col items-start w-full'>
											<div className='form__Input flex items-center gap-[1rem] w-full'>
												<img
													src={'/emailIcon.svg'}
													alt=' '
												/>
												<input
													placeholder='Email'
													value={field.state.value}
													onBlur={field.handleBlur}
													onChange={(e) =>
														field.handleChange(
															e.target.value
														)
													}
												/>
											</div>

											<FieldInfo field={field} />
										</div>
									)}
								/>

								<initiateForm.Field
									name='password'
									validators={{
										onChange: yup
											.string()
											.required('Password is required'),
										onChangeAsyncDebounceMs: 500,
										onChangeAsync: yup
											.string()
											.test(
												'no error',
												"No 'error' allowed in password",
												async (value) => {
													await new Promise((resolve) =>
														setTimeout(resolve, 1000)
													);
													return !value?.includes('error');
												}
											),
									}}
									children={(field) => (
										<div className='flex flex-col items-start w-full'>
											<div className='form__Input flex items-center gap-[1rem]  w-full'>
												<img
													src={'/passwordIcon.svg'}
													alt=''
												/>
												<input
													placeholder='Password'
													value={field.state.value}
													onBlur={field.handleBlur}
													type='password'
													onChange={(e) =>
														field.handleChange(
															e.target.value
														)
													}
												/>
											</div>

											<FieldInfo field={field} />
										</div>
									)}
								/>
								{error ? (
									<p className='text-red-600'>{error}</p>
								) : null}
								<initiateForm.Subscribe
									selector={(state) => [
										state.canSubmit,
										state.isSubmitting,
									]}
									children={([canSubmit, isSubmitting]) => (
										<button
											type='submit'
											disabled={!canSubmit}
											className=' @apply w-full bg-[#042E3E] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700];'>
											{isSubmitting ? '...' : 'Submit'}
										</button>
									)}
								/>
							</form>
						) : (
							<form
								onSubmit={(e) => {
									e.preventDefault();
									finalForm.handleSubmit();
								}}>
								<div className=''>
									<finalForm.Field
										name='otp'
										validators={{
											onChange: yup
												.string()
												.required('OTP is required')
												.min(
													6,
													'OTP must be 6 character long'
												),
											onChangeAsyncDebounceMs: 500,
											onChangeAsync: yup
												.string()
												.test(
													'no error',
													"No 'error' allowed in OTP",
													async (value) => {
														await new Promise((resolve) =>
															setTimeout(resolve, 1000)
														);
														return !value?.includes(
															'error'
														);
													}
												),
										}}
										children={(field) => (
											<div className='flex flex-col items-start w-full'>
												<div className='form__Input flex items-center gap-[1rem] w-full'>
													<input
														placeholder='OTP'
														value={field.state.value}
														onBlur={field.handleBlur}
														onChange={(e) =>
															field.handleChange(
																e.target.value
															)
														}
													/>
												</div>

												<FieldInfo field={field} />
											</div>
										)}
									/>
								</div>
								{error ? (
									<p className='text-red-600'>{error}</p>
								) : null}
								<finalForm.Subscribe
									selector={(state) => [
										state.canSubmit,
										state.isSubmitting,
									]}
									children={([canSubmit, isSubmitting]) => (
										<button
											type='submit'
											disabled={!canSubmit}
											className=' @apply w-full bg-[#042E3E] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700];'>
											{isSubmitting ? '...' : 'Submitaa'}
										</button>
									)}
								/>
								<Button
									type='button'
									className={`auth__Button mt-[2.5rem] ${
										seconds > 0 ? 'bg-gray-700 ' : ''
									}`}
									disabled={seconds > 0}
									onClick={() => {
										signInInitiateMutate.mutate({
											email: loginCredentials.email,
											password: loginCredentials.password,
										});
										setResendTimerCount(
											resendTimeCount + 1
										);
										resetTimer();
									}}>
									Resend{' '}
									{seconds > 0 ? (
										<span className='countdown'>
											<span
												style={{ '--value': seconds }}
											/>
										</span>
									) : (
										''
									)}
								</Button>
							</form>
						)}
						<div className='login__createAccount'>
							<p>
								Don&apos;t have an account?{' '}
								<span>
									<Link to='/sign-up'>
										Create an account
									</Link>
								</span>
							</p>
						</div>
					</div>
				</div>
				<div className='login__Right'>
					<img
						src={'/LoginSideBg.svg'}
						alt='LoginRightBG'
					/>
				</div>
			</div>
		</main>
	);
};

export default Login;
