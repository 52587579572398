import React, { useState } from 'react';
import './Feedback.styles.css';

const Feedback = () => {
	const [reviews, setReviews] = useState([
		{
			id: 'r001',
			customerName: 'John Doe',
			productId: 'p001',
			rating: 4,
			review: 'Great product!',
			timestamp: '2024-07-05 10:00',
		},
		{
			id: 'r002',
			customerName: 'Jane Smith',
			productId: 'p002',
			rating: 5,
			review: 'Excellent service!',
			timestamp: '2024-07-05 12:00',
		},
	]);

	const deleteReview = (id) => {
		setReviews(
			reviews.filter((review) => review.id !== id)
		);
	};

	// Function to calculate average rating and number of reviews per product
	const getProductRatingStats = (productId) => {
		const productReviews = reviews.filter(
			(review) => review.productId === productId
		);
		const totalReviews = productReviews.length;
		const totalRating = productReviews.reduce(
			(sum, review) => sum + review.rating,
			0
		);
		const averageRating =
			totalReviews > 0
				? (totalRating / totalReviews).toFixed(1)
				: 'N/A';

		return { totalReviews, averageRating };
	};

	return (
		<div className='container mx-auto p-4'>
			<h1 className='text-4xl font-bold mb-8'>
				Feedback and Reviews
			</h1>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Customer Reviews
				</h2>
				<div className='Feedback__Table'>
					<table>
						<thead>
							<tr>
								<th>Customer Name</th>
								<th>Product ID</th>
								<th>Rating</th>
								<th>Review</th>
								<th>Timestamp</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{reviews.map((review, index) => (
								<tr key={index} className='text-center'>
									<td>{review.customerName}</td>
									<td>{review.productId}</td>
									<td>{review.rating}</td>
									<td>{review.review}</td>
									<td>{review.timestamp}</td>
									<td>
										<button
											onClick={() =>
												deleteReview(review.id)
											}
											className='bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded-md'>
											Delete
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Product Ratings
				</h2>
				<div className='Feedback__Table'>
					<table>
						<thead>
							<tr>
								<th>Product ID</th>
								<th>Average Rating</th>
								<th>Number of Reviews</th>
							</tr>
						</thead>
						<tbody>
							{reviews.map((review, index) => (
								<tr key={index}>
									<td>{review.productId}</td>
									<td>
										{
											getProductRatingStats(
												review.productId
											).averageRating
										}
									</td>
									<td>
										{
											getProductRatingStats(
												review.productId
											).totalReviews
										}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Feedback;
