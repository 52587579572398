// import { AdminOverviewCard } from "../../../components";
import './Overview.styles.css';
// import { LineChart } from '@mui/x-charts/LineChart';
// import {
// 	PieChart,
// 	pieArcLabelClasses,
// } from '@mui/x-charts/PieChart';

const data = [
	{ value: 5, label: 'Visitors' },
	{ value: 10, label: 'Page Views' },
	{ value: 15, label: 'Purchase Percentage' },
];

const size = {
	width: 400,
	height: 200,
};

// const OverviewCardData = [
//     {
//         id: 1,
//         icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[3rem] h-[3rem]">
//             <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
//         </svg>,
//         header: "Total Visits",
//         value: 12,
//     },
//     {
//         id: 2,
//         icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[3rem] h-[3rem]">
//             <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
//         </svg>
//         ,
//         header: "Page Views",
//         value: "5",
//     },
//     {
//         id: 3,
//         icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[3rem] h-[3rem]">
//             <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
//         </svg>

//         ,
//         header: "Total Orders",
//         value: "10",
//     },
//     {
//         id: 4,
//         icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[3rem] h-[3rem]">
//             <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
//         </svg>
//         ,
//         header: "Average Order Vlaue",
//         value: "$3000",
//     },
// ]

const salesData = [5000, 75, 1500, 200, 300, 6000];
const revenueData = [5000, 1000, 2000, 3000, 5000, 7000];

const Overview = () => {
	return (
		<main className='overview'>
			<section className='overview__Cards'>
				{/* {OverviewCardData.map((item) => (
                    <AdminOverviewCard OverviewCardData={item} key={item.id} />
                ))} */}
			</section>
			{/* Line Chart - Total Sales & Revenue */}
			<section className='overview__SalesRevenue'>
				<h1>Total Sales & Revenue</h1>
				{/* <LineChart
					xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
					series={[
						{
							data: salesData,
							label: 'Total Sales',
							color: 'blue',
						},
						{
							data: revenueData,
							label: 'Total Revenue',
							color: 'green',
						},
					]}
					sx={{
						width: '100%',
					}}
					height={500}
				/> */}
			</section>
			{/* Visitors + Page Views + Source */}
			<section className='overview__Traffic'>
				<div className='overview__Traffic--Left'>
					{/* <PieChart
						series={[
							{
								arcLabel: (item) => `(${item.value})`,
								arcLabelMinAngle: 45,
								data,
							},
						]}
						sx={{
							[`& .${pieArcLabelClasses.root}`]: {
								fill: 'white',
								fontSize: '1.4rem',
							},
						}}
						{...size}
					/> */}
				</div>
				<div className='overview__Traffic--Right'>Left</div>
			</section>
		</main>
	);
};

export default Overview;
