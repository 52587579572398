// src/reducers/index.js
import { combineReducers } from 'redux';
import { byorReducer, categoryReducer } from './categories';
import {
	publishReportReducer,
	reportDetailedReducer,
	reportReducer,
} from './report';
import { postReducer } from './posts';

export const rootReducer = combineReducers({
	category: categoryReducer,
	report: reportReducer,
	detailedReport: reportDetailedReducer,
	publishReport: publishReportReducer,
	post: postReducer,
	byor: byorReducer,
});
