import { useState } from 'react';
import { Button } from '../../../components';
import ProductsList from './List/ProductsList.router';
import ProductsDetails from './Details/ProductsDetail.router';
import ProductsActions from './Actions/ProductsAction.router';
import '../Orders/Orders.styles.css';

const Products = () => {
	const [view, setView] = useState(0);

	const showView = () => {
		switch (view) {
			case 0:
				return <ProductsList />;
			case 1:
				return <ProductsDetails />;
			case 2:
				return <ProductsActions />;
		}
	};

	return (
		<div className='Orders__Container'>
			<h1 className='Orders__Heading'>Products Section</h1>
			<div className='Orders__BtnContainer'>
				<Button
					text='Product List'
					onClick={() => setView(0)}
					className={
						view == 0
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='Product Details'
					onClick={() => setView(1)}
					className={
						view == 1
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='Product Actions'
					onClick={() => setView(2)}
					className={
						view == 2
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
			</div>

			<div>{showView()}</div>
		</div>
	);
};

export default Products;
