import Button from '../Button/Button.component';
import { Link } from 'react-router-dom';

const Banner = () => {
	return (
		<section className='banner'>
			<div className='banner__Container'>
				<div className='banner__Left'>
					<h1>Problems come and get solved with ease</h1>
					<p>
						At HMS we are committed to delivering actionable
						insights that empower you to make informed
						decisions and stay ahead of the competition.
						Trust us to provide you with the intelligence
						you need to thrive in today&apos;s dynamic
						business landscape.
					</p>
				</div>
				<div className='banner__Right'>
					<Link to='/'>
						<Button
							text='Get Started'
							className='button__White'
						/>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Banner;
