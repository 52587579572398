import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducers/index';
import createSagaMiddleware from 'redux-saga';
import {
	watchByorSaga,
	watchCategorySaga,
} from './sagas/category';
import {
	watchDetailedReportSaga,
	watchPublishReportSaga,
	watchReportSaga,
} from './sagas/report';
import { watchPostSaga } from './sagas/post';
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(sagaMiddleware),
});

// Run the root saga
sagaMiddleware.run(watchCategorySaga);
sagaMiddleware.run(watchReportSaga);
sagaMiddleware.run(watchDetailedReportSaga);
sagaMiddleware.run(watchPublishReportSaga);
sagaMiddleware.run(watchPostSaga);
sagaMiddleware.run(watchByorSaga);
export default store;
