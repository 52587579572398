import React, { useState } from 'react';
import { buttonsData } from '../../constants/Data';

const Capabilities = () => {
	const [hoveredButton, setHoveredButton] = useState(null);

	const handleMouseEnter = (index) => {
		setHoveredButton(index);
	};

	const handleMouseLeave = () => {
		setHoveredButton(null);
	};


	return (
		<main className='capabilities'>
			{/* Banner */}
			<section className='capabilities__Banner'>
				<img
					src={'/CapabilitiesBannerImg.svg'}
					alt='capabilities-banner'
				/>
			</section>
			{/* Details Section */}
			<section className='capabilities__Details'>
				<h3>GLOBAL REPORTS</h3>
				<h1>
					At Your <span>Fingertip</span>
				</h1>
				<p>
					HMS gathers and analyzes data from nine industry sectors. This data is utilized to generate a thorough document offering a detailed analysis and overview of specific global or regional markets.  <br /><br /> 
					These reports are crucial for staying abreast of market dynamics, enabling informed business decision-making, and identifying growth prospects on both global and regional levels. <br /><br /> 
					Furthermore, these reports include assessments of the current market size and forecasts future growth or decline based on historical data and market trends. 
				</p>
			</section>
			{/* Tags */}
			<section className='capabilities__Tags'>
				<div className='capabilities__RowOne'>
					{buttonsData.slice(0, 4).map((button, index) => (
						<button
							key={button.id}
							onMouseEnter={() => handleMouseEnter(index)}
							onMouseLeave={handleMouseLeave}>
							<img
								src={
									hoveredButton === index
										? '/CapabilitiesTickIcon.svg'
										: '/PlusIcon.svg'
								}
								alt='icon-one'
							/>
							{button.text}
						</button>
					))}
				</div>
				<div className='capabilities__RowTwo'>
					{buttonsData.slice(4, 7).map((button, index) => (
						<button
							key={button.id}
							onMouseEnter={() =>
								handleMouseEnter(index + 4)
							}
							onMouseLeave={handleMouseLeave}>
							<img
								src={
									hoveredButton === index + 4
										? '/CapabilitiesTickIcon.svg'
										: '/PlusIcon.svg'
								}
								alt='icon-one'
							/>
							{button.text}
						</button>
					))}
				</div>
				<div className='capabilities__RowThree'>
					{buttonsData.slice(7, 9).map((button, index) => (
						<button
							key={button.id}
							onMouseEnter={() =>
								handleMouseEnter(index + 7)
							}
							onMouseLeave={handleMouseLeave}>
							<img
								src={
									hoveredButton === index + 7
										? '/CapabilitiesTickIcon.svg'
										: '/PlusIcon.svg'
								}
								alt='icon-one'
							/>
							{button.text}
						</button>
					))}
				</div>
			</section>
			{/* Section Bottom */}
			<section className='capabilities__Bottom'>
				<div className='capabilities__Left'>
					<h3>Faster turnaround times:</h3>
					<h1>
						Uncover hidden <span>opportunities</span>
					</h1>
					<p>
						Friends and neighbors gathered around bonfires,
						the crackling flames casting a flickering light
						on their faces. The sound of music filled the
						air as musicians played their instruments,
						encouraging spontaneous dances and joyful
						celebrations. Stars began to appear in the
						twilight sky, each one a tiny beacon of light in
						the growing darkness. The moon rose, full and
						luminous, overseeing the peaceful slumber of the
						land below.
					</p>
				</div>
				<div className='capabilities__Right'>
					<img src={'/AboutImgOne.svg'} alt='side-image' />
				</div>
			</section>
		</main>
	);
};

export default Capabilities;
