import React, { useState, useEffect } from 'react';
import Button from '../Button/Button.component';
import { Link } from 'react-router-dom';

const slides = [
	{
		image: '/HeroImageOne.svg',
		title: 'Insights',
		mainText: 'Uncover hidden opportunities',
		description:
			'Go beyond surface-level data. We help you discover the trends and insights that drive real results.',
		buttonText: 'Free consultation',
	},
	{
		image: '/HeroImageTwo.svg',
		title: 'Insights',
		mainText: 'Make data-driven decisions',
		description:
			'Why guess when you can make informed decisions? Our research empowers you to make confident choices for your business.',
		buttonText: 'Free consultation',
	},
	{
		image: '/HeroImageThree.svg',
		title: 'Insights',
		mainText: 'Turn insights into action',
		description:
			"Don't just get data, get clarity. We translate complex information into actionable strategies you can implement.",
		buttonText: 'Free consultation',
	},
];

const Hero = () => {
	const [currentSlide, setCurrentSlide] = useState(0);

	useEffect(() => {
		const slideInterval = setInterval(() => {
			setCurrentSlide(
				(prevSlide) => (prevSlide + 1) % slides.length
			);
		}, 3000);

		return () => clearInterval(slideInterval);
	}, []);

	return (
		<section data-testid='hero-section' className='hero'>
			<div className='hero__slide'>
				<img
					src={slides[currentSlide].image}
					alt=''
					className='hero__image'
				/>
				<div className='hero__Container'>
					<div className='hero__Left'>
						<h4>{slides[currentSlide].title}</h4>
						<h1>
							{slides[currentSlide].mainText
								.split(' ')
								.map((word, i) =>
									i === 1 ? (
										<span key={i}>{word} </span>
									) : (
										word + ' '
									)
								)}
						</h1>
						<p>{slides[currentSlide].description}</p>
						<Link to='/contact'>
							<Button
								text={slides[currentSlide].buttonText}
								className='button__Common hero__Adjustment'
							/>
						</Link>
					</div>
					<div className='hero__Right'></div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
