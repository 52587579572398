import React from 'react';
import { Dialog, DialogPanel,DialogBackdrop } from '@headlessui/react'

const Modal = ({ open, onClose, children }) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
        <DialogBackdrop className="fixed inset-0 bg-black/30" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
          <DialogPanel className="max-w-lg space-y-4 border bg-white p-12">
            {children}
          </DialogPanel>
        </div>
      </Dialog>
  )
}

export default Modal