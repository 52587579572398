import { useState } from 'react';
import './ProductsAction.styles.css';

const ProductsActions = () => {
	const [productName, setProductName] = useState('');
	const [productCategory, setProductCategory] =
		useState('');
	const [productPrice, setProductPrice] = useState('');
	const [productStockStatus, setProductStockStatus] =
		useState('');
	const [productVisibility, setProductVisibility] =
		useState('');

	// const getJsonData = () => {
	// 	return JSON.stringify({
	// 		name: productName,
	// 		category: productCategory,
	// 		price: productPrice,
	// 		stock_status: productStockStatus,
	// 		visibility: productVisibility,
	// 	});
	// };

	const handleAddProduct = () => {
		// Logic for adding a new product
		alert('Product Added');
	};

	const handleEditProduct = () => {
		// Logic for editing product details
		alert('Product Edited');
	};

	const handleDeleteProduct = () => {
		// Logic for deleting a product
		alert('Product Deleted');
	};

	const handleManageCategories = () => {
		// Logic for managing categories
		alert('Manage Categories');
	};

	return (
		<div className='ProductActions__Container'>
			<div className='ProductActions__FormGroup'>
				<label>Product Name:</label>
				<input
					type='text'
					value={productName}
					onChange={(e) => setProductName(e.target.value)}
				/>
			</div>
			<div className='ProductActions__FormGroup'>
				<label>Category:</label>
				<input
					type='text'
					value={productCategory}
					onChange={(e) =>
						setProductCategory(e.target.value)
					}
				/>
			</div>
			<div className='ProductActions__FormGroup'>
				<label>Price ($):</label>
				<input
					type='number'
					value={productPrice}
					onChange={(e) => setProductPrice(e.target.value)}
				/>
			</div>
			<div className='ProductActions__FormGroup'>
				<label>Stock Status:</label>
				<select
					onChange={(e) =>
						setProductStockStatus(e.target.value)
					}>
					<option value='1'>In Stock</option>
					<option value='0'>Out Of Stock</option>
				</select>
			</div>
			<div className='ProductActions__FormGroup'>
				<label>Visibility:</label>
				<select
					onChange={(e) =>
						setProductVisibility(e.target.value)
					}>
					<option value='1'>Visible</option>
					<option value='0'>Not Visible</option>
				</select>
			</div>

			<div className='ProductActions__BtnGroup'>
				<button onClick={handleAddProduct}>
					Add Product
				</button>
				<button onClick={handleEditProduct}>
					Edit Product
				</button>
				<button onClick={handleDeleteProduct}>
					Delete Product
				</button>
				<button onClick={handleManageCategories}>
					Manage Categories
				</button>
			</div>
		</div>
	);
};

export default ProductsActions;
