import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	Accordion,
	Hero,
	Banner,
	CardHome,
	CardServiceHome,
	Button,
	CardReportsHome,
	Loader,
} from '../../components';
import {
	AccordionData,
	HomeCardOneData,
	HomeServiceCardData,
} from '../../constants/Data';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination'; 
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { useDispatch, useSelector } from 'react-redux';
// import { selectPost } from '../../store/selector/post';
// import { fetchPost } from '../../store/reducers/posts';

const Home = () => {
	const [tab, setTab] = useState('tab-1');
	// const dispatch = useDispatch();
	// const posts =useSelector(selectPost)

	// useEffect(() => {
	// 	dispatch(fetchPost())
	// }, []);



	return (
		<main className='home'>
			<Helmet>
				<title>Home - Horizon Market Strategies</title>
				<meta name="description" content="Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights." />
				<meta property="og:title" content="Home - Horizon Market Strategies" />
				<meta property="og:description" content="Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights." />
				<meta property="og:site_name" content="Horizon Market Strategies" />
			</Helmet>
			<Hero />
			<section className='home__Reports'>
				<div className='home__Reports--Top'>
					<div className='home__Reports--Left'>
						<p>REPORTS</p>
						<h1>
							Latest <span>Reports</span>
						</h1>
					</div>
					<div className='home__Reports--Right'>
						<Button
							text='See All'
							className='button__Common'
						/>
					</div>
				</div>
				<div className='home__Reports--Bottom'>
					{/* {console.log(posts?.posts)}
				{posts?.posts ?<Swiper
						navigation={true}
						modules={[Navigation]}
						slidesPerView={2.5}
						spaceBetween={30}
						pagination={{ clickable: true }}>
						
							{posts.posts?.map((item) => (
								<SwiperSlide
									key={item.id}
									className='min-h-[40rem] bg-[#F4F4F4] flex flex-col gap-[4rem] items-start justify-center'>
									<CardReportsHome
										data={item}
										// onClick={() =>
										// 	navigate(`/reports/${item.id}`)
										// }
									/>
								</SwiperSlide>
							))}
					</Swiper>:null} */}
				</div>
			</section>
			<section className='home__Workflow'>
				<div className='home__Workflow--Left'>
					<div className='home__Workflow--Top'>
						<p>Workflow</p>
						<h1>
							Key <span>benefits</span>
						</h1>
					</div>
					<div className='home__Workflow__Middle'>
						<div className='home__WorkflowTabs'>
							<ul>
								<li
									onClick={() => setTab('tab-1')}
									className={
										tab === 'tab-1'
											? 'active__Tab'
											: 'default__tab'
									}>
									Competitive Edge
								</li>
								<li
									onClick={() => setTab('tab-2')}
									className={
										tab === 'tab-2'
											? 'active__Tab'
											: 'default__tab'
									}>
									Minimize risk
								</li>
								<li
									onClick={() => setTab('tab-3')}
									className={
										tab === 'tab-3'
											? 'active__Tab'
											: 'default__tab'
									}>
									Focus
								</li>
							</ul>
							{tab === 'tab-1' && (
								<div className='home__Workflow--TabData'>
									Stay ahead of the curve. Our research
									provides the intelligence you need to
									outmaneuver your competition.
								</div>
							)}
							{tab === 'tab-2' && (
								<div className='home__Workflow--TabData'>
									Make informed decisions with confidence.
									Our research helps you identify potential
									roadblocks and maximize the return on your
									investments.
								</div>
							)}
							{tab === 'tab-3' && (
								<div className='home__Workflow--TabData'>
									Let us handle the research. You can focus
									on what you do best - running your
									business.
								</div>
							)}
						</div>
					</div>
					<div className='mt-[6rem]'>
						<Link to='/contact'>
							<Button
								className='button__Common'
								text='Free Consultation'
							/>
						</Link>
					</div>
				</div>
				<div className='home__Workflow--Right'>
					<img
						src={'/HomeWorkflowImg.svg'}
						alt='Workflow'
					/>
				</div>
			</section>
			{/* Featured Services */}
			<section className='home__Services'>
				<div className='home__Services--Top'>
					<p>SERVICES</p>
					<h1>
						Featured <span>Services</span>
					</h1>
				</div>
				<div className='home__Services--Bottom'>
					<Swiper
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						modules={[Autoplay]}
						slidesPerView={4}
						spaceBetween={30}
						pagination={{ clickable: true }}>
						{HomeServiceCardData &&
							HomeServiceCardData.map((item) => (
								<SwiperSlide
									key={item.id}
									className='min-h-[40rem] bg-[#F4F4F4] rounded-[2rem] p-[3rem] flex flex-col gap-[4rem] items-start '>
									<CardServiceHome data={item} />
								</SwiperSlide>
							))}
					</Swiper>
				</div>
			</section>
			{/* Why Choose Us */}
			<section className='home__Wcu'>
				<h1>
					Why <span>choose us?</span>
				</h1>
				<div className='home__Wcu--Cards'>
					{HomeCardOneData.map((item) => (
						<CardHome data={item} key={item.id} />
					))}
				</div>
			</section>
			{/* Banner */}
			<section className='home__Banner'>
				<Banner />
			</section>
			{/* FAQs */}
			<section className='home__Faq'>
				<h1>FAQs</h1>
				<div className='home__AccContainer'>
					{AccordionData.map((item) => (
						<Accordion data={item} key={item.id} />
					))}
				</div>
			</section>
		</main>
	);
};

export default Home;
