import React, { useEffect, useState, useRef } from 'react';
import {
	Link,
	NavLink,
	useNavigate,
} from 'react-router-dom';
import { IoSearch } from 'react-icons/io5';
import { FiShoppingCart } from 'react-icons/fi';
// import Avatar from 'react-avatar';
import Button from '../Button/Button.component';
import {
	ACCESS_TOKEN_KEY,
	REFRESH_TOKEN_KEY,
} from '../../constants/token.constant';
import Token from '../../lib/token';
import CookieToken from '../../lib/cookie-token';
import { jwtDecode } from 'jwt-decode';
import { isTokenExpired } from '../../lib/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../../store/selector/selector';
import { fetchCategory } from '../../store/reducers/categories';
import {
	articleLinks,
	capabilitiesLinks,
	companyLinks,
} from '../../constants/Data';
// import { useRecoilState } from 'recoil';
// import { userState } from '../../store/user';
// import { useGetCategoriesQuery } from '../../queries/Categories/Category.queries';
// import { categoriesState } from '../../store/Categories';

const Navbar = () => {
	const isLoading = false;
	const error = null;
	const dispatch = useDispatch();
	const category = useSelector(selectCategory);

	const token = CookieToken.getToken(ACCESS_TOKEN_KEY);
	const [user, setUser] = useState();
	useEffect(() => {
		dispatch(fetchCategory());
		if (token) {
			if (!isTokenExpired(token)) {
				const usr = jwtDecode(token);
				setUser({
					...user,
					token: token,
					name: usr?.user?.name,
					image: usr?.user?.image,
					email: usr?.user?.email,
				});
			} else {
				CookieToken.removeToken(ACCESS_TOKEN_KEY);
				Token.removeToken(REFRESH_TOKEN_KEY);
			}
		}
	}, []);

	const [searchVisible, setSearchVisible] = useState(false);
	const [avatarDropdownVisible, setAvatarDropdownVisible] =
		useState(false);
	const [openMenu, setOpenMenu] = useState(null);
	const searchInputRef = useRef(null);
	const avatarRef = useRef(null);

	const toggleSearchVisibility = () => {
		setSearchVisible(!searchVisible);
	};

	const toggleAvatarDropdownVisibility = () => {
		setAvatarDropdownVisible(!avatarDropdownVisible);
	};

	// Handle search functionality here.
	const handleSearchSubmit = () => {};

	const menuRef = useRef(null);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] =
		useState(false);
	const [
		isMarketplaceDropdownOpen,
		setIsMarketplaceDropdownOpen,
	] = useState(false);
	const [
		isResourcesDropdownOpen,
		setIsResourcesDropdownOpen,
	] = useState(false);

	const companyDropdownRef = useRef(null);
	const marketplaceDropdownRef = useRef(null);
	const resourcesDropdownRef = useRef(null);

	const handleClickOutside = (event) => {
		// if (
		//   menuRef.current &&
		//   !menuRef.current.contains(event.target) &&
		//   companyDropdownRef.current &&
		//   !companyDropdownRef.current.contains(event.target) &&
		//   marketplaceDropdownRef.current &&
		//   !marketplaceDropdownRef.current.contains(event.target) &&
		//   resourcesDropdownRef.current &&
		//   !resourcesDropdownRef.current.contains(event.target)
		// ) {
		//   setIsMenuOpen(false);
		//   setIsCompanyDropdownOpen(false);
		//   setIsMarketplaceDropdownOpen(false);
		//   setIsResourcesDropdownOpen(false);
		// }
	};

	useEffect(() => {
		document.addEventListener(
			'mousedown',
			handleClickOutside
		);
		return () => {
			document.removeEventListener(
				'mousedown',
				handleClickOutside
			);
		};
	}, []);

	const handleLinkClick = () => {
		setIsMenuOpen(false);
		setIsCompanyDropdownOpen(false);
		setIsMarketplaceDropdownOpen(false);
		setIsResourcesDropdownOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				searchInputRef.current &&
				!searchInputRef.current.contains(event.target)
			) {
				setSearchVisible(false);
			}
			//   if (avatarRef.current && !avatarRef.current.contains(event.target)) {
			//     setAvatarDropdownVisible(false);
			//   }
		};
		document.addEventListener(
			'mousedown',
			handleClickOutside
		);
		return () =>
			document.removeEventListener(
				'mousedown',
				handleClickOutside
			);
	});

	const [hoveredCategory, setHoveredCategory] =
		useState(null);
	const [dropdownStyle, setDropdownStyle] = useState({});

	const handleMouseEnter = (e, categoryName) => {
		const rect = e.target.getBoundingClientRect();
		const dropdownWidth = 800; // Approximate width of the dropdown
		const dropdownHeight = 200; // Approximate height of the dropdown
		let newStyle = {
			left: '100%',
			top: '0',
		};

		if (rect.right + dropdownWidth > window.innerWidth) {
			newStyle.left = `-${dropdownWidth}px`;
		}

		if (rect.bottom + dropdownHeight > window.innerHeight) {
			newStyle.top = `-${dropdownHeight}px`;
		}

		setHoveredCategory(categoryName);
		setDropdownStyle(newStyle);
	};

	return (
		<nav className='bg-[#EDEDED] sticky top-0 z-20 w-full'>
			<div className='flex justify-between items-center w-[90%] mx-auto py-[2.5rem]'>
				<Link
					to='/'
					className='navbar__Left'
					onClick={handleLinkClick}>
					<img
						src='/HMSLogo.png'
						alt='HMS Logo'
						className='w-[8.5rem] h-auto'
					/>
				</Link>
				<div
					ref={menuRef}
					className={`${
						isMenuOpen ? 'block' : 'hidden'
					} w-full md:flex md:w-auto `}>
					<ul
						className={
							searchVisible ? 'hidden' : 'nav__MainLinks'
						}>
						{/* INDUSTRIES LINK */}
						<li>
							<button
								ref={companyDropdownRef}
								onClick={() => {
									setIsCompanyDropdownOpen(
										!isCompanyDropdownOpen
									);
									setIsMarketplaceDropdownOpen(false);
									setIsResourcesDropdownOpen(false);
								}}
								className='flex items-center gap-[1rem]'>
								Industries
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
						</li>
						{/* CAPABILITIES LINK */}
						<li>
							<button
								ref={marketplaceDropdownRef}
								onClick={() => {
									setIsMarketplaceDropdownOpen(
										!isMarketplaceDropdownOpen
									);
									setIsCompanyDropdownOpen(false);
									setIsResourcesDropdownOpen(false);
								}}
								className='flex items-center gap-[1rem]'>
								Capabilities
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
						</li>
						<li>
							<button
								ref={resourcesDropdownRef}
								onClick={() => {
									setIsResourcesDropdownOpen(
										!isResourcesDropdownOpen
									);
									setIsCompanyDropdownOpen(false);
									setIsMarketplaceDropdownOpen(false);
								}}
								className='flex items-center gap-[1rem]'>
								Company
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
						</li>
						<li>
							<Link to='/'>FAQs</Link>
						</li>
						{/* <li>
              <Link to="/store" onClick={handleLinkClick}>Shop</Link>
            </li> */}
					</ul>
				</div>
				<div className='navbar__Icons'>
					<div
						className={
							searchVisible
								? 'flex'
								: 'navbar__IconCover bg-[#042E3E]'
						}>
						<IoSearch
							className={searchVisible ? 'hidden' : ''}
							color='#FFF'
							fontSize={16}
							fontWeight={500}
							onClick={toggleSearchVisibility}
						/>
						{searchVisible && (
							<div
								className='navbar__SearchInputContainer'
								ref={searchInputRef}>
								<input
									placeholder='Search...'
									type='text'
									className='navbar__SearchInput'
								/>
								<button onClick={handleSearchSubmit}>
									<IoSearch
										color='#000'
										fontSize={24}
										fontWeight={500}
										onClick={toggleSearchVisibility}
									/>
								</button>
							</div>
						)}
					</div>
					<div className='navbar__IconCover bg-[#868686]'>
						<FiShoppingCart
							color='#FFF'
							fontSize={16}
							fontWeight={500}
						/>
					</div>

					{/* AVATAR DROPDOWN */}

					{/* <div ref={avatarRef} className="navbar__AvatarContainer">
            <Avatar
              name={user.name}
              className="navbar__Avatar"
              onClick={toggleAvatarDropdownVisibility}
            />
            {avatarDropdownVisible && (
              <AvatarDropdown
                userData={user}
                toggleAvatarDropdownVisibility={toggleAvatarDropdownVisibility}
              />
            )}
          </div> */}
				</div>
			</div>
			{/* CAPABILITIES Dropdown */}
			<CapabilitiesDropdown
				isMarketplaceDropdownOpen={
					isMarketplaceDropdownOpen
				}
				handleLinkClick={handleLinkClick}
			/>

			{/* COMPANY DROPDOWN */}
			<CompanyDropdwon
				isResourcesDropdownOpen={isResourcesDropdownOpen}
				handleLinkClick={handleLinkClick}
			/>

			{/* INDUSTRIES DROPDOWN */}
			<div
				className={`${
					isCompanyDropdownOpen ? 'block' : 'hidden'
				} mt-1 bg-[#042E3E]  overflow-y-scroll`}>
				<div className='grid grid-cols-4 grid-rows-4 gap-4 p-4  w-[90%] mx-auto py-12'>
					{isLoading ? (
						<div>Loading...</div>
					) : error ? (
						<div>{error?.message}</div>
					) : (
						<>
							{category?.map((e) => (
								<div className='relative group nvf'>
									<NavLink
										to={`/reports/${e.name}`}
										className='renderCategory__name flex   justify-center'>
										{e.name}
									</NavLink>
									<div className='nvf absolute left-0 overflow-y-scroll max-h-[30rem]  top-[100%] hidden group-hover:bg-[#0e3545] group-hover:flex z-[1] flex-col min-w-[25rem]'>
										{e.subcategories.map((i) => (
											<NavLink
												className='renderCategory__Child'
												to={`/reports/${e.name}/${i}`}
												onClick={handleLinkClick}>
												{i}
											</NavLink>
										))}
									</div>
								</div>
							))}
						</>
					)}
				</div>
				<div className='nav__Cont w-[90%] mx-auto'>
					<div className='nav__BoxThree'>
						<div className='nav__BoxThree--Left'>
							<h1>Industries</h1>
							<p>
								From brand strategy to sales performance, we
								blend a unique combination of expertise,
								advanced analytics, products and platforms,
								creating the most meaningful understanding
								of how people think and act.
							</p>
						</div>
						{/* <div className="nav__BoxThree--Right">
              <Link to="/store" onClick={handleLinkClick}>
                <Button
                  type="button"
                  text="Go to store"
                  className="Button__WhiteSquare w-[25rem]"
                />
              </Link>
            </div> */}
					</div>
					<div className='nav__dropdownDetails'>
						<div className='nav__dropdownLeft flex gap-[8rem]'>
							<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
								<h1 className='uppercase'>Contact</h1>
								<a
									href='tel:+447700142117'
									className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
									+44 7700 142117
								</a>
								<a
									href='tel:+12136167815'
									className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
									+1 213 616 7815
								</a>
							</div>
							<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
								<h1 className='uppercase'>Email</h1>
								<a
									href='mailto:info@horizon-markets.com'
									className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
									info@horizon-markets.com
								</a>
							</div>
							<div className='nav__InfoOne flex flex-col '>
								<h1 className='uppercase'>Follow</h1>
								<div className='flex gap-[1rem]'>
									<Link
										to='https://www.linkedin.com/company/horizon-market-strategies/'
										target='_blank'>
										<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
											Facebook
										</span>
									</Link>
									<Link
										onClick={handleLinkClick}
										to='https://www.linkedin.com/company/horizon-market-strategies/'
										target='_blank'>
										<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
											Linkedin
										</span>
									</Link>
									<Link
										onClick={handleLinkClick}
										to='https://www.youtube.com/@HorizonmarketStrategies'
										target='_blank'>
										<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
											Youtube
										</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;

// AVATAR DROPDOWN FUNCTION :
export const AvatarDropdown = ({
	userData,
	toggleAvatarDropdownVisibility,
}) => {
	const [user, setUser] = useState();
	const navigate = useNavigate();
	// if (userData.token != '')
	return (
		<div className='navbar__AvatarDropdown'>
			<div className='navbar__AvatarDropdownItemMain'>
				{/* <Avatar
					name={userData.name}
					className='navbar__AvatarSmall'
				/> */}
				<span>{userData.name}</span>
			</div>
			<Link
				to='/dashboard'
				onClick={toggleAvatarDropdownVisibility}
				className='navbar__AvatarDropdownItem'>
				<img
					src={
						userData.image
							? userData.image
							: '/ProfileIcon.svg'
					}
					alt='profile-icon'
				/>
				<span>Profile</span>
			</Link>
			<Link
				to='/dashboard'
				onClick={toggleAvatarDropdownVisibility}
				className='navbar__AvatarDropdownItem'>
				<img src={'/SettingsIcon.svg'} alt='profile-icon' />
				<span>Dashboard</span>
			</Link>
			<Link
				to='/checkout'
				onClick={toggleAvatarDropdownVisibility}
				className='navbar__AvatarDropdownItem'>
				<img src={'/OrdersIcon.svg'} alt='profile-icon' />
				<span>Orders</span>
			</Link>
			<div
				className='navbar__AvatarDropdownItem'
				onClick={() => {
					setUser({
						name: '',
						email: '',
						image: '',
						subscriptionTier: '',
						token: '',
						refreshToken: '',
					});
					navigate('/');
					Token.removeToken(REFRESH_TOKEN_KEY);
					CookieToken.removeToken(ACCESS_TOKEN_KEY);
				}}>
				<img src={'/LogoutIcon.svg'} alt='profile-icon' />
				<span onClick={toggleAvatarDropdownVisibility}>
					Logout
				</span>
			</div>
		</div>
	);
};

// CAPABILITIES DROPDOWN :
export const CapabilitiesDropdown = ({
	isMarketplaceDropdownOpen,
	handleLinkClick,
}) => {
	return (
		<div
			className={`${
				isMarketplaceDropdownOpen ? 'block' : 'hidden'
			} mt-1 bg-[#042E3E] overflow-y-scroll`}>
			<div className='grid grid-cols-4 grid-rows-4 gap-4 p-4  w-[90%] mx-auto py-12'>
				{capabilitiesLinks?.map((items) => (
					<Link
						to={items.path}
						id={items.id}
						className='renderCategory__name flex   justify-center'
						onClick={handleLinkClick}>
						{items.name}
					</Link>
				))}
			</div>
			<div className='w-[90%] mx-auto '>
				<h1 className='text-[3.2rem] font-[400] text-[#FFF]'>
					Capabilities
				</h1>
				<p className='mt-[2rem] text-[1.6rem] font-[500] text-[#FFF] max-w-[70%]'>
					From brand strategy to sales performance, we blend
					a unique combination of expertise, advanced
					analytics, products and platforms, creating the
					most meaningful understanding of how people think
					and act.
				</p>
				{/* <Link to="/store"        onClick={handleLinkClick}>
          <Button
            type="button"
            text="Go to store"
            className="mt-[2rem] Button__WhiteSquare w-[25rem] py-[2rem]"
          /></Link> */}

				<div className='nav__dropdownDetails'>
					<div className='nav__dropdownLeft flex gap-[8rem]'>
						<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
							<h1 className='uppercase'>Contact</h1>
							<a
								href='tel:+447700142117'
								className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
								+44 7700 142117
							</a>
							<a
								href='tel:+12136167815'
								className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
								+1 213 616 7815
							</a>
						</div>
						<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
							<h1 className='uppercase'>Email</h1>
							<a
								href='mailto:info@horizon-markets.com'
								className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
								info@horizon-markets.com
							</a>
						</div>
						<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
							<h1 className='uppercase'>Follow</h1>
							<div className='flex gap-[1rem]'>
								<Link
									to='https://www.linkedin.com/company/horizon-market-strategies/'
									target='_blank'>
									<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
										Facebook
									</span>
								</Link>
								<Link
									onClick={handleLinkClick}
									to='https://www.linkedin.com/company/horizon-market-strategies/'
									target='_blank'>
									<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
										Linkedin
									</span>
								</Link>
								<Link
									onClick={handleLinkClick}
									to='https://www.youtube.com/@HorizonmarketStrategies'
									target='_blank'>
									<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
										Youtube
									</span>
								</Link>
							</div>
						</div>
					</div>
					<div className='nav__dropdownRight'>
						<Link to='/sign-in' onClick={handleLinkClick}>
							<Button
								type='button'
								text='Login'
								className='mt-[2rem] Button__WhiteSquare w-[25rem] py-[2rem]'
							/>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

// COMPANY DROPDOWN :
export const CompanyDropdwon = ({
	isResourcesDropdownOpen,
	handleLinkClick,
}) => {
	return (
		<div
			className={`${
				isResourcesDropdownOpen ? 'block' : 'hidden'
			}  mt-1 bg-[#042E3E] h-[65rem] overflow-y-scroll`}>
			<div className='w-[90%] mx-auto py-12'>
				<h1 className='text-[#FFF] text-[1.8rem] font-[700] uppercase whitespace-nowrap mb-[2rem]'>
					COMPANY
				</h1>
				<div className='grid grid-cols-4  gap-4 p-4   mx-auto'>
					{companyLinks.map((items) => (
						<Link
							to={items.path}
							id={items.id}
							className='renderCategory__name flex   justify-center'
							onClick={handleLinkClick}>
							{items.name}
						</Link>
					))}
				</div>

				<h1 className='text-[#FFF] text-[1.8rem] font-[700] uppercase whitespace-nowrap my-[2rem]'>
					ARTICLE
				</h1>
				<div className='grid grid-cols-4  gap-4 p-4  mx-auto'>
					{articleLinks.map((items) => (
						<Link
							to={items.path}
							id={items.id}
							className='renderCategory__name flex   justify-center'
							onClick={handleLinkClick}>
							{items.name}
						</Link>
					))}
				</div>
			</div>
			<div className='w-[90%] mx-auto '>
				<div className='flex gap-[5rem] items-center justify-between'>
					<div className='flex-[10]'>
						<h1 className='text-[3.2rem] font-[400] text-[#FFF]'>
							Capabilities
						</h1>
						<p className='mt-[2rem] text-[1.6rem] font-[500] text-[#FFF] max-w-[70%]'>
							From brand strategy to sales performance, we
							blend a unique combination of expertise,
							advanced analytics, products and platforms,
							creating the most meaningful understanding of
							how people think and act.
						</p>
					</div>
					{/* <div className="flex-[2] flex items-end justify-end">
            <Link to="/store"        onClick={handleLinkClick}>
              <Button
                type="button"
                text="Go to store"
                className="mt-[2rem] Button__WhiteSquare w-[25rem] py-[2rem]"
              /></Link>

          </div> */}
				</div>

				<div className='nav__dropdownDetails'>
					<div className='nav__dropdownLeft flex gap-[8rem]'>
						<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
							<h1 className='uppercase'>Contact</h1>
							<a
								href='tel:+447700142117'
								className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
								+44 7700 142117
							</a>
							<a
								href='tel:+12136167815'
								className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
								+1 213 616 7815
							</a>
						</div>
						<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
							<h1 className='uppercase'>Email</h1>
							<a
								href='mailto:info@horizon-markets.com'
								className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
								info@horizon-markets.com
							</a>
						</div>
						<div className='nav__InfoOne flex flex-col '>
							<h1 className='uppercase'>Follow</h1>
							<div className='flex gap-[1rem]'>
								<Link
									to='https://www.linkedin.com/company/horizon-market-strategies/'
									target='_blank'>
									<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
										Facebook
									</span>
								</Link>
								<Link
									onClick={handleLinkClick}
									to='https://www.linkedin.com/company/horizon-market-strategies/'
									target='_blank'>
									<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
										Linkedin
									</span>
								</Link>
								<Link
									onClick={handleLinkClick}
									to='https://www.youtube.com/@HorizonmarketStrategies'
									target='_blank'>
									<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
										Youtube
									</span>
								</Link>
							</div>
						</div>
					</div>
					<div className='nav__dropdownRight'>
						<Link to='/sign-in' onClick={handleLinkClick}>
							<Button
								type='button'
								text='Login'
								className='mt-[2rem] Button__WhiteSquare w-[25rem] py-[2rem]'
							/>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
